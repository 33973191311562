import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7C_gZx-2JDigBZ7gMI2YexwzKDVTR_yU",
  authDomain: "fellpunzel.firebaseapp.com",
  projectId: "fellpunzel",
  storageBucket: "fellpunzel.appspot.com",
  messagingSenderId: "326262866582",
  appId: "1:326262866582:web:2acdc45ed3cca1dc9a38b7",
  measurementId: "G-M96RJLZTMD"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const { Timestamp, FieldValue } = firebase.firestore;
firebase.analytics();

export { Timestamp, FieldValue };
export default firebaseApp.firestore();
