<template>
  <div>
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 1px 1px 2px #333;"
      :interval="4000"
      fade
      indicators
      img-height="100"
    >
      <b-carousel-slide
        content-visible-up="lg"
        text="Gönnen Sie Ihrem Liebling das volle Verwöhnprogramm."
        :img-src="image2"
        fluid
      ></b-carousel-slide>
      <b-carousel-slide
        content-visible-up="lg"
        text="Wenn das Fell in die Augen weht wird es Zeit Fellpunzel zu besuchen. Buchen Sie jetzt Ihren Termin."
        :img-src="image1"
        fluid
      ></b-carousel-slide>

      <b-carousel-slide
        content-visible-up="lg"
        text="Wir bekommen auch den zotteligsten Hund wieder hin."
        :img-src="image3"
        fluid
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
// import firebase from "firebase";
export default {
  data() {
    return {
      image1:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/HomeCarousel%2FImage1.png?alt=media",
      image2:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/HomeCarousel%2FImage2.png?alt=media",
      image3:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/HomeCarousel%2FImage3.png?alt=media"
    };
  }
  // mounted() {
  //   var storageRef = firebase.storage().ref("/HomeCarousel");

  //   storageRef
  //     .child("benjamin-lehman-qwIeh5M8bF8-unsplash.jpg")
  //     .getDownloadURL()
  //     .then(url => {
  //       this.image = url;
  //     });
  // }
};
</script>
