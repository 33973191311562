import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/Home",
    name: "Home",
    component: Home
  },
  {
    path: "/About",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "Antragsformular" */ "../views/About.vue")
  },
  {
    path: "/Leistungen",
    name: "Leistungen",
    component: () =>
      import(/* webpackChunkName: "Antraege" */ "../views/Leistungen.vue")
  },
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: () =>
      import(/* webpackChunkName: "Support" */ "../views/Kontakt.vue")
  },
  {
    path: "/Kundenbewertung",
    name: "Kundenbewertung",
    component: () =>
      import(/* webpackChunkName: "Status" */ "../views/Kundenbewertung.vue")
  },
  {
    path: "/Salon",
    name: "Salon",
    component: () =>
      import(/* webpackChunkName: "KartenVerwalten" */ "../views/Salon.vue")
  },
  {
    path: "/Termine",
    name: "Termine",
    component: () =>
      import(/* webpackChunkName: "KartenVerwalten" */ "../views/Termine.vue")
  },
  {
    path: "/Fotos",
    name: "Fotos",
    component: () =>
      import(/* webpackChunkName: "KartenVerwalten" */ "../views/Fotos.vue")
  },
  {
    path: "/Terminbuchen",
    name: "Terminbuchen",
    component: () =>
      import(
        /* webpackChunkName: "KartenVerwalten" */ "../views/Terminbuchen.vue"
      )
  }
];

const router = new VueRouter({
  routes
});

export default router;
