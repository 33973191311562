<template>
  <div>
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand to="/Home" class="d-lg-none">
        <img :src="logo" height="80" alt="Logo" />
      </b-navbar-brand>

      <b-navbar-toggle ref="burger" target="nav-collapse"></b-navbar-toggle>

      <b-collapse
        id="nav-collapse"
        is-nav
        class="justify-content-center text-center"
      >
        <b-navbar-nav>
          <b-nav-item to="/Home">Start</b-nav-item>
          <b-nav-item to="/Leistungen">Leistungen</b-nav-item>
          <b-nav-item to="/Kontakt">Kontakt</b-nav-item>
          <!-- <b-nav-item to="/Kundenbewertung">Kundenbewertung</b-nav-item> -->
          <b-nav-item to="/Salon">Salon</b-nav-item>
          <b-nav-item to="/Termine">Termine</b-nav-item>

          <b-nav-item href="https://www.instagram.com/fellpunzel_hundesalon/">
            <b-icon icon="instagram"></b-icon>
          </b-nav-item>
          <b-nav-item
            href="https://www.facebook.com/Fellpunzel-171758497739509/?ref=py_c"
          >
            <b-icon icon="facebook"
          /></b-nav-item>
        </b-navbar-nav>
        <!-- button sichtbar nur im kleinen Display -->
        <b-button
          class="my-2 my-sm-0 d-lg-none d-block"
          variant="outline-fellpunzel"
          to="/Terminbuchen"
          @click="collapseNav"
        >
          TERMIN VEREINBAREN</b-button
        >
        <!-- button sichtbar nur im großen Display -->
        <b-button
          size="sm"
          class="ml-2 my-2 my-sm-0 d-none d-lg-inline"
          variant="outline-fellpunzel"
          to="/Terminbuchen"
          >TERMIN VEREINBAREN</b-button
        >
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => {
    return {
      logo: require("../../assets/figures/Logos/Fellpunzel_Querformat_mitUnterzeile_1c-gold.svg")
    };
  },
  methods: {
    // Um Burger menü auch über Button schließen zu lassen wird der Toggle button click emitted
    collapseNav() {
      this.$refs.burger.$el.click();
    }
  }
};
</script>
