import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Bootstrap from "bootstrap";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBath, faCut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBath, faCut);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Bootstrap);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
